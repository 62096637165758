<template>
    <div class="page-group-ranking">
        <headeBar left-arrow :color="header_color" :title="header_title" :background="header_background"
            :opacity="header_opacity" :placeholder="false" @click-left="newAppBack" />
        <div class="headbg">
            <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/group-invite-202304/ranking/rule.png"
                class="rule-logo" @click="showRule = true" />
        </div>
        <div class="tickets">
            <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/group-invite-202304/ranking/piao.png"
                alt class="logo" />
            <div class="mytk">我的票 {{ myticket }} 张</div>
            <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/group-invite-202304/ranking/invite.png"
                alt class="invite" @click="showTS" />
        </div>
        <div class="records">
            <div class="tabbox">
                <div class="voucher-list">
                    <template v-if="voteRecord.length">
                        <div class="voucher-model" v-for="(item, index) in voteRecord">
                            <div class="v1">
                                <img alt="" :src="item.headImg" class="img" />
                                <div class="pub_onefull ellipsis">
                                    <div class="l1 ellipsis">{{ item.teacherName }}</div>
                                    <div class="l2">{{ cityObj[item.cityId] || '' }}排名 {{ item.rank < 1 ? '未上榜' : item.rank
                                    }}</div>
                                    </div>
                                </div>
                                <div class="v2">
                                    <div class="l3">你贡献了{{ item.voteNum }}票</div>
                                    <div class="tp" @click="vote(item)">投票</div>
                                </div>
                            </div>
                    </template>
                    <div class="nonevote" v-else>
                        <img class="noneimg"
                            src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/group-invite-202304/none.png"
                            alt />
                        暂无投票记录
                    </div>
                </div>
            </div>
        </div>

        <div class="rankhead">
            <div class="city" @click="showCity = true">{{ cityName }}<van-icon name="play" class="down" /></div>
            <div class="tr" @click="openSearch">
                搜索教练
                <img alt=""
                    src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/group-invite-202304/ranking/search2.png"
                    class="search-icon" />
            </div>
        </div>
        <div class="rankbody">

            <van-list v-if="hasdata" v-model="loading" :finished="finished" @load="loadList" :immediate-check="false"
                :offset="50" finished-text="没有更多了">
                <div class="top">票数相同以投票时间来排名，投票越早则排名越靠前</div>
                <div class="teacher-list">
                    <div class="teacher-model" v-for="item in teacherList" :key="item.id" :class="'rank' + item.rank">
                        <div class="l1 pimg">
                            <div class="logo"></div>
                            <template>
                                {{ item.totalVote ? item.rank : '未上榜' }}
                            </template>
                        </div>
                        <div class="l2">
                            <img :src="$getImgUrl(item.headImg)" alt />
                        </div>
                        <div class="l3 ellipsis">
                            <div class="l31 ellipsis">{{ item.teacherName }}</div>
                            <div class="l32">票数：<span class="number">{{ item.totalVote }}</span></div>
                        </div>
                        <div class="l4" @click="vote(item)">投票</div>
                    </div>
                </div>
            </van-list>
            <div v-else class="nonedata">
                <img class="n1"
                    src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/group-invite-202304/none.png"
                    alt />
                <div class="n2">暂无教练上榜，先上榜更快攒到票哦</div>
            </div>
        </div>
        <van-overlay :show="isShareWx" :z-index="1500" @click="isShareWx = false">
            <div class="tip-box" @click.stop>
                <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/anniversary2021/share-mini.png"
                    class="tip-img" />
            </div>
        </van-overlay>
        <ChooseTeacher @setShareTeacher="setShareTeacher" ref="refChooseTeacher" />
        <van-popup v-model="showRule" position="bottom" safe-area-inset-bottom class="pop">
            <div class="pop-title">
                教练排行榜规则
                <van-icon name="cross" class="pop-close" color="#fff" @click="showRule = false" />
            </div>
            <div class="pop-rule-box">
                <div class="rule-title">
                    活动规则及说明：
                </div>
                <div class="rule-content">
                    “我的教练榜上有名”为喜欢的教练打call！C位出道、瞩目超鹿，你手中的每一票都至关重要，在活动期间：
                    <div>1、用户每成功邀请一个未上过团课的好友进馆体验首节团课，即可获得一票；</div>
                    <div>2、拥有票的用户可以向教练进行投票，每次投票之后不可撤销；</div>
                    <div>3、根据教练获得的票数，榜单上会实时展示教练排名；</div>
                    <div>4、如用户存在违规行为（包括但不限于以不正当手段获取利益），超鹿运动有权根据情节严重对用户进行处罚，包括但不限于取消用户参与资格、撤销相关交易，不予发放奖励、收回奖励、依法追究其法律责任等。
                    </div>
                </div>
                <div class="rule-title">
                    教练荣誉解锁：
                </div>
                <div class="rule-content">
                    1、城市周榜排名NO.1的教练，解锁APP城市首页banner位霸屏奖励，可在APP首页banner、弹窗、开屏展示3天，每周五至周日展示上一周(周五至周四)的教练;
                    <div class="tt">
                        2、城市月榜排名NO.1的教练，解锁APP开屏页、首页banner、首页弹窗位霸屏奖励，以【月度人气教练】在APP开屏页、首页banner第一屏、首页弹窗位展示7天，即为12月1日-7日。
                    </div>
                    <div class="tt">
                        活动时间：
                        2024年11月1日-2024年11月30日
                    </div>
                    <div class="tt">
                        本次活动最终解释权归超鹿所有，如果您对活动有疑问，请在超鹿 APP 上联系在线客服或者拨打400-700-5678进行反馈(在线时间:9:30-12:00/13:30-18:30)
                    </div>
                </div>
            </div>

        </van-popup>
        <van-popup v-model="showSearch" position="bottom" safe-area-inset-bottom class="pop">
            <div class="pop-title">
                搜索教练
                <van-icon name="cross" class="pop-close" color="#fff" @click="showSearch = false" />
            </div>
            <div class="search-pop">
                <div class="search-box">
                    <img alt=""
                        src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/group-invite-202304/search.png"
                        class="search-icon" @click="searchData" />
                    <form action="JavaScript：return true;" @submit.prevent="searchData">
                        <input type="search" v-model.trim="searchName" class="ipt" placeholder="输入教练的名字" />
                    </form>
                </div>
                <div class="sb">
                    <div class="search-teacher-list" v-if="hasSearchData">
                        <div class="teacher-model" v-for="item in searchTeacherList" :key="item.id"
                            :class="'rank' + item.rank">
                            <div class="l1 pimg">
                                <div class="logo"></div>
                                <template>
                                    {{ item.totalVote ? item.rank : '未上榜' }}
                                </template>
                            </div>
                            <div class="l2">
                                <img :src="$getImgUrl(item.headImg)" alt />
                            </div>
                            <div class="l3 ellipsis">
                                <div class="l31 ellipsis">{{ item.teacherName }}</div>
                                <div class="l32">票数：<span class="number">{{ item.totalVote }}</span></div>
                            </div>
                            <div class="l4" @click="vote(item)">投票</div>
                        </div>
                    </div>
                    <div v-else class="nonedata">
                        <img class="n1"
                            src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/group-invite-202304/none.png"
                            alt />
                        <div class="n2">暂无匹配的教练</div>
                    </div>
                </div>
            </div>
        </van-popup>
        <van-popup v-model="showSUC" position="center" class="pop-bt">
            <div class="bg">
                <div class="s1">{{ sucObj.teacherName }}</div>
                <div class="s2">票数：{{ sucObj.ticket }}</div>
            </div>
            <div class="close" @click="showSUC = false"></div>
        </van-popup>
        <van-popup v-model="showNone" position="center" class="pop-bt">
            <div class="bg2" @click="toHome"></div>
            <div class="close" @click="showNone = false"></div>
        </van-popup>
        <van-popup v-model="showCity" position="bottom" safe-area-inset-bottom class="popcity">
            <div class="buy-title">
                选择城市
                <van-icon name="cross" class="buy-close" color="#9AA1A9" @click="showCity = false" />
            </div>
            <div class="city-list">
                <div v-for="(item, index) in cityList" :key="index" class="city-model"
                    :class="{ 'city-active': item.cityId == chooseCityId }" @click="setCity(item)">
                    {{ item.areaName }}
                    <div class="checkbox"></div>
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>
import { newAppBack, initBack, saveCreateImg, webAppShare, defaultApp } from '@/lib/appMethod';
import headeBar from '@/components/app/headBar';
// import commonShare from '@/components/commonShare';
import userMixin from '@/mixin/userMixin';
import wx from 'weixin-js-sdk';
import headerMixin from '@/mixin/headerMixin';
import ChooseTeacher from './components/chooseTeacher.vue';

const webPath = `${window.location.origin}/#/superdeer-activity/group-invite-202411/accept`;
const webUrl = `pages/webView/index?webUrl=${encodeURIComponent(webPath)}`;
export default {
    components: {
        headeBar,
        // commonShare,
        ChooseTeacher
    },
    mixins: [userMixin, headerMixin],
    data() {
        return {
            showCity: false,
            chooseCityId: '',
            isShareWx: false,
            finished: false,
            loading: false,
            hasdata: true,
            voteRecord: [],
            myticket: 0,
            pageNum: 1,
            cityList: [],
            showShare: false, // 是否显示分享弹窗
            shareParams: {
                title: '走，去团课！上课即领帆布袋',
                content: '走，去团课！上课即领帆布袋',
                multiple: '1',
                userName: 'gh_7f5b57b6a76e',
                miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2',
                url: 'url',
                miniImage: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/group-invite-202304/mini.png',
                path: webUrl,
            },
            teacherList: [],
            searchName: '',
            hasSearchData: true,
            showSearch: false,
            searchTeacherList: [],
            showSUC: false,
            sucObj: {},
            showNone: false,
            cityObj: {},
            showRule: false
        }
    },
    computed: {
        cityName() {
            return this.cityObj[this.chooseCityId] || '未选择城市';
            // return this.cityList.find(d => {
            //     return d.cityId == this.chooseCityId
            // })?.areaName || '未选择城市'
        }
    },
    methods: {
        saveCreateImg,
        newAppBack,
        showTS() {
            this.$refs.refChooseTeacher.show = true;
        },
        setCity(item) {
            this.showCity = false;
            this.chooseCityId = item.cityId;
            this.getData();
            this.reset();
            this.resetSearch();
        },
        resetSearch() {
            this.searchTeacherList = [];
            this.hasSearchData = true;
            this.searchName = '';
        },
        openSearch() {
            this.resetSearch();
            this.showSearch = true;
        },
        toHome() {
            this.$router.push('/superdeer-activity/group-invite-202411/index');
        },
        reset() {
            this.pageNum = 1;
            this.getRanking(true);
        },
        setShareTeacher(tObj) {
            if (tObj) {
                console.log(tObj);
                this.shareParams.path = `${webUrl}&inviteUid=${this.userId}&location=1&cityId=1&teacherId=${tObj.teacherId}`;
            }
            else {
                this.shareParams.path = `${webUrl}&inviteUid=${this.userId}&location=1&cityId=1`;
            }
            this.wxInvite();
        },
        searchData() {
            if (!this.searchName) {
                this.$toast('请输入教练名字');
                return;
            }
            this.$axios
                .post(this.baseURLApp + "/sche/oldBringNew/searchTeacher", {
                    // cityId: this.chooseCityId,
                    page: 1,
                    size: 1000,
                    token: this.token,
                    userId: this.userId,
                    teacherName: this.searchName
                })
                .then((res) => {
                    this.hasSearchData = res.data.length > 0;
                    this.searchTeacherList = res.data;
                })
        },
        vote(item) {
            if (!this.myticket) {
                this.showNone = true;
                return;
            }
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                overlay: true,
                loadingType: 'spinner',
            })
            this.$axios
                .post(this.baseURLApp + "/sche/oldBringNew/vote", {
                    // cityId: this.chooseCityId,
                    token: this.token,
                    userId: this.userId,
                    teacherId: item.teacherId
                })
                .then((res) => {
                    this.sucObj = {
                        teacherName: item.teacherName,
                        ticket: res.data || item.totalVote + 1
                    }
                    this.showSUC = true;
                    this.reset();
                    this.getData();
                    if (this.searchTeacherList.length) {
                        this.searchData()
                    }
                }).catch().then(() => {
                    this.$toast.clear()
                })
        },
        setAddress(obj) {
            console.log(obj);
        },
        chooseTeacher(index) {
            this.teacherIndex = index;
        },
        wxInvite() {
            if (!this.userId) {
                this.$toast('请稍后再试');
                return;
            }

            if (this.appTypeStr === 'mini') {
                wx.miniProgram.postMessage({
                    data: {
                        type: 'share',
                        shareImage: this.shareParams.miniImage,
                        shareTitle: this.shareParams.title,
                        shareUrl: this.shareParams.path,
                    },
                })
                this.isShareWx = true
            } else {
                let params = {
                    scene: 0,
                    ...this.shareParams
                }
                let miniParams = JSON.parse(JSON.stringify(params))
                miniParams.image = miniParams.miniImage
                webAppShare('3', miniParams)
            }
        },
        getCity() {
            this.$axios.post(this.baseURLApp + "/scheduleLesson/cityList").then((res) => {
                this.cityList = res.data;
                const obj = {}
                for (let i = 0; i < res.data.length; i++) {
                    obj[res.data[i].cityId] = res.data[i].areaName
                }
                this.cityObj = obj;
                console.log(this.cityObj);
            })
        },
        getRanking(refresh) {//获取排行榜
            const size = 12;
            this.$axios
                .post(this.baseURLApp + "/sche/oldBringNew/rank", {
                    cityId: this.chooseCityId,
                    page: this.pageNum,
                    size,
                    token: this.token,
                    userId: this.userId,
                    prePageRank:refresh?null:this.teacherList.length?this.teacherList[this.teacherList.length-1]?.rank||null:null
                })
                .then((res) => {
                    const data = res.data;
                    //[
                    // {
                    //     "teacherId": "827127217690513408",
                    //     "teacherName": "112",
                    //     "headImg": "https://img.chaolu.com.cn/userfiles/1/files/teacher/teacher/pic/2021/03/jfyj.jpg",
                    //     "totalVote": 6,
                    //     "rank": 1
                    // },
                    // {
                    //     "teacherId": "1ba84c7d17794ab2a005b6c159cd9c7a",
                    //     "teacherName": "黄锦雯",
                    //     "headImg": "https://img.chaolu.com.cn/dev/01f470674a1f496ba61ef5d84fed0cad/userfiles/files/teacher/teacher/pic/2022/02/test1.jpg",
                    //     "totalVote": 5,
                    //     "rank": 2
                    // },
                    // {
                    //     "teacherId": "0118368ec871432ca33b98cae6e356aa",
                    //     "teacherName": "叮噹",
                    //     "headImg": "https://img.chaolu.com.cn/userfiles/1/files/teacher/teacher/pic/2021/10/dd.jpg",
                    //     "totalVote": 4,
                    //     "rank": 3
                    // },
                    // {
                    //     "teacherId": "819226529987825664",
                    //     "teacherName": "江叶琴(江江)",
                    //     "headImg": "https://img.chaolu.com.cn/userfiles/1/files/teacher/teacher/pic/2021/03/cxkebao.png",
                    //     "totalVote": 1,
                    //     "rank": 4
                    // }
                    //]

                    if (
                        // true
                        (!data || data.length == 0) &&
                        this.teacherList.length == 0
                    ) {
                        this.hasdata = false;
                        this.teacherList = [];
                    } else {
                        this.hasdata = true;
                        if (refresh) {
                            this.teacherList = data;
                        } else {
                            this.teacherList.push(...data);
                        }

                        if (data.length < size) {
                            this.finished = true;
                            console.log('fs');
                        } //全部加载完了
                        else {
                            this.finished = false;
                        }
                    }
                    this.loading = false;
                })
        },
        getData() {
            this.$axios
                .post(this.baseURLApp + "/sche/oldBringNew/listUserVoteTeacher", {
                    cityId: this.chooseCityId,
                    token: this.token,
                    userId: this.userId
                })
                .then((res) => {
                    this.voteRecord = res.data.voteRecord;
                    this.myticket = res.data.remainVote;
                })
        },
        loadList() {
            this.pageNum += 1;
            this.getRanking();
        }
    },
    mounted() {
        this.bindHeaderScroll(this.$el, 200, (scrollTop, morehalf) => {

        });
    },
    async created() {
        console.log(this.$route.query);
        initBack();
        await this.$getAllInfo(['userId', 'cityId']);
        this.chooseCityId = this.cityId;
        this.getData();
        this.getCity();
        this.getRanking();
    }
}
</script>
<style lang="less" scoped>
.page-group-ranking {
    position: absolute;
    height: 100vh;
    top: 0;
    // bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    background: #FEE8C6;
    padding: 0 0 100px;

    div {
        box-sizing: border-box;
    }

    img {
        vertical-align: top;
        width: 100%;
    }

    .headbg {
        height: 638px;
        background: #FEE8C6 url(https://img.chaolu.com.cn/ACT/group-invite-202304/ranking/headbg.png) no-repeat;
        background-size: 100% auto;

        .rule-logo {
            position: absolute;
            top: 204px;
            right: 0;
            width: 50px;
            height: 86px;
        }
    }

    .tickets {
        width: 530px;
        height: 88px;
        margin: -290px auto 32px;
        background: linear-gradient(180deg, #FFECD0 0%, #FFF9E9 100%);
        border-radius: 108px;
        border: 4px solid #fff;
        display: flex;
        align-items: center;

        .logo {
            margin-left: 36px;
            margin-right: 14px;
            width: 48px;
            height: 48px;
        }

        .mytk {
            flex: 1;
            font-size: 28px;
            color: #242831;
            font-weight: bold;
        }

        .invite {
            width: 220px;
            height: 100%;
        }
    }

    .records {
        // width: 702px;
        height: 288px;
        background: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/group-invite-202304/ranking/jilubg.png);
        background-size: cover;
        margin: 0 24px 32px;

        .tabbox {
            overflow-x: scroll;
            overflow-y: hidden;
            padding-left: 24px;
            position: relative;
            z-index: 1;

            &::-webkit-scrollbar {
                display: none;
            }

            .voucher-list {
                display: flex;
                float: left;
                margin-top: 96px;

                .nonevote {
                    display: flex;
                    align-items: center;
                    font-size: 26px;
                    margin-top: 32px;
                    color: #3C454E;

                    .noneimg {
                        width: 104px;
                        height: 104px;
                        margin-right: 46px;
                        margin-left: 32px;
                    }
                }

                .voucher-model {
                    width: 302px;
                    height: 168px;
                    margin-right: 16px;
                    background: #FFF1DF;
                    border-radius: 12px;
                    color: #432F17;
                    padding: 16px 20px 0;

                    .v1 {
                        display: flex;
                        align-items: center;

                        .img {
                            width: 80px;
                            height: 80px;
                            margin-right: 16px;
                            border-radius: 50%;
                            overflow: hidden;
                            background: #f5f5f5;
                            flex-shrink: 0;
                        }

                        .l1 {
                            font-size: 24px;
                            font-weight: bold;
                            margin-bottom: 8px;
                        }

                        .l2 {
                            font-size: 20px;
                        }
                    }

                    .v2 {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 4px;

                        .l3 {
                            font-size: 20px;
                        }

                        .tp {
                            width: 96px;
                            height: 52px;
                            line-height: 54px;
                            border-radius: 8px;
                            background: #FD3665;
                            color: #fff;
                            text-align: center;
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }

    .rankhead {
        height: 100px;
        background: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/group-invite-202304/ranking/new.png);
        background-size: cover;
        padding: 38px 220px;
        position: relative;
        margin: 0 24px;

        .city {
            font-size: 24px;
            color: #242831;
            line-height: 1;
        }

        .down {
            font-size: 20px;
            transform: rotate(90deg);
            margin-left: 10px;
            position: relative;
            top: -2px;
        }

        .tr {
            position: absolute;
            right: 24px;
            top: 14px;
            color: #fff;
            font-size: 28px;
            line-height: 1;
            display: flex;
            align-items: center;

            .search-icon {
                width: 40px;
                height: 40px;
                position: relative;
                top: 2px;
                margin-left: 6px;
            }
        }
    }

    .rankbody {
        background: #FEFCF6;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        min-height: 436px;
        margin: 0 24px;

        .top{
            font-size: 22px;
            color: #666;
            margin-left: 28px;
            margin-bottom: 32px;
        }
        .teacher-list {
            padding: 0 24px 24px;

            .teacher-model {
                border-radius: 8px;
                background: #FFF1DF;
                height: 136px;
                margin-bottom: 16px;
                display: flex;
                align-items: center;
                padding: 0 28px 0 22px;

                .l1 {
                    width: 70px;
                    height: 48px;
                    background-size: cover;
                    font-size: 22px;
                    font-weight: bold;
                    text-align: center;
                    color: #7B5340;
                    line-height: 48px;
                    position: relative;

                    .logo {
                        width: 48px;
                        height: 48px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background-size: cover;
                    }
                }

                &.rank1 {
                    .pimg {
                        color: transparent;

                        .logo {
                            background-image: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/group-invite-202304/p1.png);
                        }
                    }

                    .l2 {
                        border: 4px solid #FFA902;
                    }
                }


                &.rank2 {
                    .pimg {
                        color: transparent;

                        .logo {
                            background-image: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/group-invite-202304/p2.png);
                        }
                    }

                    .l2 {
                        border: 4px solid #789EFF;
                    }
                }

                &.rank3 {
                    .pimg {
                        color: transparent;

                        .logo {
                            background-image: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/group-invite-202304/p3.png);
                        }
                    }

                    .l2 {
                        border: 4px solid #DD6C2C;
                    }
                }

                .l2 {
                    margin: 0 24px;
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    position: relative;
                    background: #D0D0D0;
                    overflow: hidden;

                    &::after {
                        position: absolute;
                        content: '空缺中';
                        font-size: 24px;
                        white-space: nowrap;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%) scale(0.8);
                        color: #fff;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        z-index: 2;
                        border-radius: 50%;
                    }
                }


                .l3 {
                    flex: 1;
                    margin-right: 20px;

                    .l31 {
                        font-weight: bold;
                        font-size: 28px;
                        color: #242831;
                    }

                    .l32 {
                        font-size: 22px;
                        color: #7B5340;
                        font-weight: bold;

                        .number {
                            color: #FF5C25;
                        }
                    }

                }

                .l4 {
                    background: #FD3665;
                    border-radius: 8px;
                    text-align: center;
                    width: 112px;
                    height: 52px;
                    line-height: 54px;
                    color: #fff;
                    font-size: 24px;
                }
            }
        }

    }

    .nonedata {
        padding-top: 52px;

        .n1 {
            width: 120px;
            height: 120px;
            display: block;
            margin: 70px auto 52px;
        }

        .n2 {
            font-size: 26px;
            color: #242831;
            text-align: center;
        }
    }

    .pop {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;

        .pop-title {
            color: #432F17;
            font-weight: bold;
            background: linear-gradient(180deg, #FCF2DA 0%, #FFFFFF 100%);
            font-size: 40px;
            text-align: center;
            line-height: 120px;
            position: relative;

            .pop-close {
                position: absolute;
                right: 32px;
                top: 38px;
                width: 44px;
                height: 44px;
                line-height: 44px;
                border-radius: 50%;
                background: rgba(39, 40, 49, 0.2);
                font-size: 28px;
            }
        }

    }

    .popcity {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;

        .buy-title {
            font-weight: bold;
            color: #242831;
            font-size: 32px;
            border-bottom: 1px solid #EEEEEE;
            height: 120px;
            padding: 0 32px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .buy-close {
            font-size: 36px;
        }

        .city-list {
            padding: 8px 32px 32px;

            .city-model {
                margin-top: 24px;
                height: 128px;
                background: #F5F5F5;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 32px;
                font-weight: bold;

                .checkbox {
                    width: 32px;
                    height: 32px;
                    border: 1px solid #DDD;
                    border-radius: 50%;
                    margin-right: 8px;
                }
            }

            .city-active {
                border: 4px solid #242831;

                .checkbox {
                    width: 48px;
                    height: 48px;
                    background: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/spring2023/check.png);
                    background-size: 100% 100%;
                    border: none;
                    margin: 0;
                }
            }
        }

        .next {
            line-height: 96px;
            background: #FFDE00;
            border-radius: 8px;
            margin-top: 64px;
            font-weight: bold;
            text-align: center;
        }
    }

    .search-pop {
        .search-box {
            border-radius: 8px;
            background: #F5F5F5;
            height: 72px;
            display: flex;
            align-items: center;
            padding: 0 16px;
            margin: 0 32px 38px;

            input[type="search"] {
                -webkit-appearance: none;
            }

            input::-webkit-search-cancel-button {
                display: none;
            }

            .search-icon {
                width: 40px;
                height: 40px;
                margin-right: 10px;
            }

            .ipt {
                border: none;
                background: transparent;
                font-size: 30px;
                width: 100%;
                height: 100%;
            }
        }

        .sb {
            height: 750px;
            overflow: auto;
        }

        .search-teacher-list {
            padding: 0 34px 0;

            .teacher-model {
                border-radius: 8px;
                background: #FFF1DF;
                height: 136px;
                margin-bottom: 16px;
                display: flex;
                align-items: center;
                padding: 0 28px 0 22px;

                .l1 {
                    width: 70px;
                    height: 48px;
                    background-size: cover;
                    font-size: 22px;
                    font-weight: bold;
                    text-align: center;
                    color: #7B5340;
                    line-height: 48px;
                    position: relative;

                    .logo {
                        width: 48px;
                        height: 48px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background-size: cover;
                    }
                }

                &.rank1 {
                    .pimg {
                        color: transparent;

                        .logo {
                            background-image: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/group-invite-202304/p1.png);
                        }
                    }

                    .l2 {
                        border: 4px solid #FFA902;
                    }
                }


                &.rank2 {
                    .pimg {
                        color: transparent;

                        .logo {
                            background-image: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/group-invite-202304/p2.png);
                        }
                    }

                    .l2 {
                        border: 4px solid #789EFF;
                    }
                }

                &.rank3 {
                    .pimg {
                        color: transparent;

                        .logo {
                            background-image: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/group-invite-202304/p3.png);
                        }
                    }

                    .l2 {
                        border: 4px solid #DD6C2C;
                    }
                }

                .l2 {
                    margin: 0 24px;
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    position: relative;
                    background: #D0D0D0;
                    overflow: hidden;

                    &::after {
                        position: absolute;
                        content: '空缺中';
                        font-size: 24px;
                        white-space: nowrap;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%) scale(0.8);
                        color: #fff;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        z-index: 2;
                    }
                }

                .l3 {
                    flex: 1;
                    margin-right: 20px;

                    .l31 {
                        font-weight: bold;
                        font-size: 28px;
                        color: #242831;
                    }

                    .l32 {
                        font-size: 22px;
                        color: #7B5340;
                        font-weight: bold;

                        .number {
                            color: #FF5C25;
                        }
                    }

                }

                .l4 {
                    background: #FD3665;
                    border-radius: 8px;
                    text-align: center;
                    width: 112px;
                    height: 52px;
                    line-height: 54px;
                    color: #fff;
                    font-size: 24px;
                }
            }
        }
    }

    .pop-bt {
        background: transparent;

        .bg {
            width: 558px;
            height: 420px;
            background-image: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/group-invite-202304/ranking/suc.png);
            background-size: cover;
            color: #B64B0F;
            text-align: center;

            .s1 {
                font-weight: bold;
                font-size: 36px;
                padding-top: 190px;
                margin-bottom: 50px;
            }

            .s2 {
                font-weight: bold;
                font-size: 48px;
            }
        }

        .bg2 {
            width: 558px;
            height: 420px;
            background-image: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/group-invite-202304/ranking/notk.png);
            background-size: cover;
        }

        .close {
            width: 60px;
            height: 60px;
            margin: 64px auto 0;
            background-image: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/group-invite-202304/ranking/close.png);
            background-size: 100% 100%;
        }
    }

    .tip-box {
        position: relative;

        .tip-img {
            position: absolute;
            top: 7px;
            right: 130px;
            width: 300px;
        }
    }

    .pop-rule-box {
        color: #5A0A00;
        padding: 24px 32px;

        .rule-title {
            font-weight: bold;
            font-size: 28px;
            line-height: 1;
            margin-bottom: 16px;
        }

        .rule-content {
            font-size: 24px;
            margin-bottom: 40px;
            line-height: 36px;
        }

        .tt {
            margin-top: 40px;
        }
    }

    @bei: 1px;

    .canvascss {
        position: fixed;
        bottom: -200%;
        // top: 0;
        width: @bei*750;
        height: @bei*1332;
        margin: 0 auto;
        box-sizing: border-box;

        .canvascss_bg {
            width: 100%;
            height: 100%;
        }

        .lt {
            position: absolute;
            left: @bei*30px;
            top: @bei*50px;
            display: flex;
            align-items: center;
            color: #fff;
            font-size: @bei*20px;

            .uhead {
                width: @bei*80;
                height: @bei*80;
                border-radius: 50%;
                margin-right: @bei*20;
            }

            .uname {
                font-weight: bold;
                margin-bottom: @bei*12;
            }
        }



        .ucode {
            position: absolute;
            right: @bei*39;
            bottom: @bei*39;
            z-index: 1;
            width: @bei*176;
            height: @bei*176;
            border-radius: 50%;
            overflow: hidden;
            border: @bei*6 solid #ff9162;
        }

    }

    /deep/.canvas-box {
        padding-left: 40px !important;
        padding-right: 40px !important;
        box-sizing: border-box;

        .create-img {
            width: 100% !important;
        }
    }
}</style>